// Packages
import React from "react";
import { graphql } from "gatsby";

// Components
import Container from "~components/common/Container";
import Layout from "~components/layouts/Layout";

// Types
import type { SiteMetadata, GatsbyImagePropsMeta, Location } from "~types/Gatsby";

interface DataProps {
	site: {
		siteMetadata: SiteMetadata,
	};
	seoImage: {
		facebook: GatsbyImagePropsMeta,
		twitter: GatsbyImagePropsMeta,
	};
	lautenschlagerLogo: {
		publicURL: string,
	};
}

export default function Page({ data, location }: { data: DataProps, location: Location }) {
	return (
		<Layout
			location={location}
			title="Impressum"
			desc=""
			imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
			noIndex
		>
			<Container className="prose">
				<h1>Impressum</h1>

				<h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
				<p>
					Kotur Entsorgungstechnik GmbH
					<br />
					{data.site.siteMetadata.contact.street}
					<br />
					{data.site.siteMetadata.contact.plz} {data.site.siteMetadata.contact.city}
				</p>

				<p>
					Handelsregister: HRB 32301
					<br />
					Registergericht: Amtsgericht Duisburg
				</p>

				<p>
					<strong>Vertreten durch:</strong>
					<br />
					Michael Kotur
				</p>

				<h2>Kontakt</h2>
				<p>
					Telefon: {data.site.siteMetadata.contact.phone}
					<br />
					Telefax: {data.site.siteMetadata.contact.fax}
					<br />
					E-Mail: {data.site.siteMetadata.contact.mail}
				</p>

				<h2>Umsatzsteuer-ID</h2>
				<p>
					Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:
					<br />
					DE 327136410
				</p>

				<h2>EU-Streitschlichtung</h2>
				<p>
					Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:{" "}
					<a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">
						https://ec.europa.eu/consumers/odr/
					</a>
					.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
				</p>

				<h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
				<p>
					Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
					teilzunehmen.
				</p>
			</Container>
			<Container>
				<div className="mx-auto my-16 mt-3 max-w-xl bg-gray-100 p-2 text-center">
					<p className="mb-1 text-xs">Website erstellt von:</p>
					<a href="https://lautenschlager.de/leistungen/webdesign-kassel/" target="_blank" rel="dofollow noreferrer">
						<img
							src={data.lautenschlagerLogo.publicURL}
							title="Webdesigner Kassel - Lautenschlager Marketing & Entwicklung"
							alt="Webdesigner Kassel - Lautenschlager Marketing & Entwicklung"
							className="mx-auto"
						/>
						<br />
						Webdesigner Kassel - Lautenschlager Marketing & Entwicklung
					</a>
				</div>
			</Container>
		</Layout>
	);
}

export const query = graphql`
	{
		site {
			siteMetadata {
				contact {
					city
					fax
					plz
					street
					phone
					mail
				}
				siteUrl
			}
		}
		seoImage: file(relativePath: { eq: "placeholder-meta.png" }) {
			facebook: childImageSharp {
				gatsbyImageData(height: 630, width: 1200, formats: JPG, quality: 100)
			}
			twitter: childImageSharp {
				gatsbyImageData(aspectRatio: 1, formats: JPG, quality: 100)
			}
		}

		lautenschlagerLogo: file(relativePath: { eq: "webdesign-kassel-lautenschlager-logo.png" }) {
			publicURL
		}
	}
`;
